import React, { Component } from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import ArrowRightSolidIcon from '../images/icons/arrow-right-solid.svg';
import ArrowLeftSolidIcon from '../images/icons/arrow-left-solid.svg';

import ReactHtmlParser from 'react-html-parser';


// import ReactGA from 'react-ga'
import PopupForm from "../components/popupForm";



import { graphql } from "gatsby";



class AlumniPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
            selectedLocation: "Australia", // State variable for selected location filter
            currentPage: 1,
            itemsPerPage: 6
		};
	}

     // Function to handle changes in the location filter
     handleLocationFilter = (location) => {
        this.setState({ selectedLocation: location, currentPage: 1 });
    };

	// Function to handle "Next" page click
	handleNextPage = () => {
		this.setState((prevState) => ({ currentPage: prevState.currentPage + 1 }), () => {
			const filterContainer = document.querySelector('.alumni-box');
			if (filterContainer) {
				const topPos = filterContainer.getBoundingClientRect().top + window.scrollY - 60;
				window.scrollTo({ top: topPos, behavior: 'smooth' });
			}
		});
	};
	
	// Function to handle "Previous" page click
	handlePreviousPage = () => {
		this.setState((prevState) => ({ currentPage: Math.max(prevState.currentPage - 1, 1) }), () => {
			const filterContainer = document.querySelector('.alumni-box');
			if (filterContainer) {
				const topPos = filterContainer.getBoundingClientRect().top + window.scrollY - 60;
				window.scrollTo({ top: topPos, behavior: 'smooth' });
			}
		});
	};



	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};


	
	componentDidMount() {


		var getInTouchLinks = document.querySelectorAll('a[href="#get-in-touch"]');


		getInTouchLinks.forEach(function(link) {
			
		  link.addEventListener('click', function(event) {
			event.preventDefault();
			
			document.getElementById('book-an-appointment').click();
		  });
		});




	



	}
	render() {

		const post = this.props.data.allWordpressPage.edges[0].node;
		const metatitle = post.acf.meta_title;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase;
		const canonical_url = post.acf.canonical_url;

        const { selectedLocation, currentPage } = this.state;
        const alumniRecords = this.props.data.allWordpressWpAlumniRecords;

        // Extract unique locations from alumniRecords
        const locations = alumniRecords ? [...new Set(alumniRecords.edges.map(({ node }) => node.acf.location))] : [];

        // Filter alumni records based on selected location
        let filteredAlumniRecords = selectedLocation
            ? alumniRecords.edges.filter(({ node }) => node.acf.location === selectedLocation)
            : alumniRecords.edges;

        // Pagination
        const perPage = 6; // Number of items per page
        const totalPages = Math.ceil(filteredAlumniRecords.length / perPage);
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        filteredAlumniRecords = filteredAlumniRecords.slice(startIndex, endIndex);


		

		var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
		if (post.featured_media) {
			headerBackground = post.featured_media.source_url;
		}
		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
						<title>{metatitle}</title>
						<meta name="description" content={metadescription} />
						{(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
						{(() => { if (post.acf.canonical_url != '' && post.acf.canonical_url != null) { return (<link rel="canonical" href={canonical_url} />) } })()}
						{(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
						<link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />
					</Helmet>


					<div class="newStyle font-poppin  scholarships students">
						<div className="container">
							<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
								<li class="breadcrumb-item">
									<a href="/">Home </a>
								</li>
								<li class="breadcrumb-item active">{post.title}</li>
							</ol>
						</div>
						<div id="scholarship-band" class="top-bar-style-two top-message font-weight-500 text-center text-white"><span>{post.acf.banner_section.text_1}</span></div>
						<section class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-25px-top sm-padding-30px-bottom  position-relative scholar-banner bg-cover345br " style={{ backgroundImage: `url(${post.acf.banner_section.background_image?.source_url})` }}
						>

							<div class="container">
								<div class="row">
									<div class="col-7 col-xl-9 col-sm-9 col-md-9 position-relative page-title-large ">

										<h2 class="yellow-background font-weight-500 alt-font  letter-spacing-1px text-black text-uppercase margin-20px-bottom d-none-991">{ReactHtmlParser(post.acf.banner_section.text_1)}</h2>

										<h1 class="text-white alt-font font-weight-500 letter-spacing-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-15px-bottom text-uppercase">{ReactHtmlParser(post.acf.banner_section.text_2)}</h1>

									

										<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md "href={post.acf.banner_section.button_link.replace(/&amp;/g, '&')}>{post.acf.banner_section.button_text}</a>


									</div>
								</div>
							</div>
							
						</section>

                        

						<section class="padding-50px-top pb-0">
							<div class="container">
								<div class="row">
								<div class="col-12 text-center col-lg-12 col-sm-12">
									
				

									<h2 class="color-blue">{post.acf.students_intro_section.intro_title}</h2>
									{ReactHtmlParser(post.acf.students_intro_section.intro_content)}
									
									
								</div>


									
									
								</div>







                                <div class="row margin-20px-top alumni-box">
								<div class="col-12 text-center col-lg-12 col-sm-12">
									
				

									<h2 class="color-blue">{post.acf.meet_our_alumni_title}</h2>

                                    <div className="filter-container">
   
									<ul className="alumni-country-filter">
    {locations.map((location, index) => (
        location !== "Other" && (
            <li key={index} className={location === selectedLocation ? "active" : ""} onClick={() => this.handleLocationFilter(location)}>
                {location}
            </li>
        )
    ))}
    {locations.includes("Other") && (
        <li key="others" className={selectedLocation === "Other" ? "active" : ""} onClick={() => this.handleLocationFilter("Other")}>
            Others
        </li>
    )}
</ul>

</div>
                    
                                    
									</div>
                                    </div>

                    <div className="row">
                        {filteredAlumniRecords.map(({ node }, index) => (
                            <div key={node.id} className="col-12 col-md-6 col-sm-12 text-center margin-10px-bottom">
                                       <div class="lp-testimonial-head alumni-head">
                                
                                    
                                <div class="image-alumni">  <img 
        src={node.featured_media ? node.featured_media.source_url : "https://assets.theworldgrad.com//wp-content/uploads/2024/03/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg"} 
        alt={node.title}  
    /></div><br/>
           
                                <div class="lp-testimonial-head-detail">
                                  <p class="mb-0 font-weight-500 color-blue">{node.title}</p>
                                  <p class="font-weight-500 mb-2 color-blue">{node.acf.degree}</p>
                                  <p class="mb-0 color-orng">{node.acf.university_name}</p>
                                </div>
                               
                              </div>
                            </div>
                        ))}
                    </div>

 {/* Pagination */}
 <div className="pagination-container row">
	<div class="col-12 col-md-2">
                        {currentPage > 1 && (
                            <button onClick={this.handlePreviousPage} class="prev-alumni"><img src={ArrowLeftSolidIcon} alt="<-" className="left-icon newicon newicon-black " />Previous</button>
                        )}
</div>
						  {/* Page numbers */}
						  <div className="col-12 col-md-8 page-numbers text-center">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => this.setState({ currentPage: i + 1 })}
                className={currentPage === i + 1 ? "active" : ""}
              >
                {i + 1}
              </button>
            ))}
          </div>
		  <div class="col-12 col-md-2">	
                        {currentPage < totalPages && (
                            <button onClick={this.handleNextPage} class="next-alumni">Next <img src={ArrowRightSolidIcon} alt="<-" className="left-icon newicon newicon-black " /></button>
                        )}
                    </div>
		   </div>


									
									
							



							</div>
			</section>

						<section class="padding-30px-tb scholarship-block bg-fast-yellow ">
							<div class="container">
								<div class="row ">
									<div class="col-12  text-center  ">
										<div class="no-margin-bottom sm-margin-20px-top">
											<h2  >{post.acf.cta_1.cta_title}
</h2>
											
										</div>
									
										<a
											data-animation="animated zoomInUp"
											class="btn btn-theme effect btn-theme-rev btn-theme-rev-orng btn-md   margin-10px-tb modal-popup "
											href={post.acf.banner_section.button_link.replace(/&amp;/g, '&')}>{post.acf.cta_1.cta_button_text}</a>
										
									</div>
								</div>
							</div>
						</section>



					</div>

				</Layout>
			</div>
		);
	}
}
export default AlumniPage;
export const alumni = graphql`
    {
		allWordpressPage(filter: {wordpress_id: {in: 19922}}) {
			edges {
			  node {
				wordpress_id
				title
				featured_media {
				  source_url
				}
				content
				acf {
					meta_title
					meta_description
					focus_keyphrase
					canonical_url
					dynamic_schema
				  banner_section {
					text_1
					text_2
					
					button_link
					button_text
					background_image {
					  source_url
					}
				  }
				 
                  meet_our_alumni_title
				  students_intro_section {
					
					intro_content
					
					
					intro_title
					
				
				  }
				  cta_1 {
					cta_button_link_new {
					source_url
}
					cta_button_text
					cta_title
				  }
				 
				  }
				}
			  }
			}
            allWordpressWpAlumniRecords {
                edges {
                  node {
                    id
                    title
                    acf {
                      degree
                      location
                      university_name
                    }
                    featured_media {
                      source_url
                    }
                  }
                }
              }  

     
}
`
